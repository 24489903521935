<template>
  <div>
    <!--顶部工具条-->
    <el-col :span="24">
      <el-form :inline="true">
        <el-form-item label="接口名称:">
          <el-input v-model="filters.name" placeholder="请输入关键字" clearable @clear='clearContent' @input='() =>(filters.name = filters.name.replace(/\s/g,""))' @keyup.enter.native='getApiModules'></el-input>
        </el-form-item>
        <el-form-item label="启用标识:">
          <el-select v-model="filters.selectc" @change='getApiModules'>
            <el-option
              v-for="item in optionsData"
              :key="item.value"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        </el-form-item>
      </el-form>
    </el-col>
    <!--列表-->
    <el-table
      :data="treeData"
      highlight-current-row
      v-loading="listLoading"
      @selection-change="selsChange"
      @current-change="selectCurrentRow"
      row-key="ID"
      border
      lazy
      :load="load"
      :tree-props="{children: 'children', hasChildren: 'HasChildren'}"
      style="width: 100%;"
      ref="multipleTable"
      v-if="isRouterAlive"
      >
          <!-- <el-table-column type="selection" width="50" align="center"></el-table-column> -->
          <el-table-column label="接口名称" width="200">
              <template slot-scope="scope">
              <i class="fa" :class="scope.row.Icon"></i>

              {{scope.row.Name}}
              </template>
          </el-table-column>
          <el-table-column prop="LinkUrl" label="链接地址" width></el-table-column>
          <el-table-column prop="IsApi" label="是否API接口" width="200" align="center">
              <template slot-scope="scope">
              <el-tag
                  :type="!scope.row.IsApi  ? 'danger' : 'success'"
                  disable-transitions
              >{{!scope.row.IsApi ? "否":"是"}}</el-tag>
              </template>
          </el-table-column>
          <el-table-column prop="CreateTime" label="创建时间" :formatter="formatCreateTime" width align="center"></el-table-column>
          <el-table-column prop="IsEnable" label="启用标识" width="200" align="center">
              <template slot-scope="scope">
              <el-tag
                  :type="!scope.row.IsEnable  ? 'danger' : 'success'"
                  disable-transitions
              >{{!scope.row.IsEnable ? "禁用":"启用"}}</el-tag>
              </template>
          </el-table-column>
      </el-table>
      <!--新增界面-->
      <el-dialog
      title="新增"
      :visible.sync="addFormVisible"
      v-model="addFormVisible"
      :close-on-click-modal='false'
      >
          <el-form :model="addForm" label-width="100px" :rules="addFormRules" ref="addForm" style="max-width: 700px;">
              <el-form-item label="接口名称：" prop="Name">
              <el-input v-model="addForm.Name" auto-complete="off" @input='() =>(addForm.Name = addForm.Name.replace(/\s/g,""))'></el-input>
              </el-form-item>

              <el-form-item label="接口地址：">
                  <el-input v-model="addForm.LinkUrl" auto-complete="off"></el-input>
              </el-form-item>
              <el-form-item prop="IsApi" label="是否API：" width sortable>
                  <el-switch v-model="addForm.IsApi"></el-switch>
              </el-form-item>
              <el-form-item prop="ParentId" label="父级目录：" width sortable>
                  <el-cascader
                      v-model="addForm.ParentId"
                      :options="options"
                      :props="defaultProps"
                      :key="isResouceShow"
                      filterable
                      change-on-select
                  ></el-cascader>
              </el-form-item>
              <el-form-item label="启用标识：" prop="IsEnable">
              <el-select v-model="addForm.IsEnable" placeholder="请选择状态">
                  <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.Name"
                  :value="item.value"
                  ></el-option>
              </el-select>
              </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click.native="addFormVisible = false">取消</el-button>
              <el-button type="primary" @click.native="addSubmit" :loading="listLoading">提交</el-button>
          </div>
      </el-dialog>
      <!--编辑界面-->
      <el-dialog
      title="编辑"
      :visible.sync="editFormVisible"
      v-model="editFormVisible"
      :close-on-click-modal='false'
      >
          <el-form :model="editForm" label-width="100px" :rules="editFormRules" ref="editForm" style="max-width: 700px;">
              <el-form-item label="接口名称：" prop="Name">
              <el-input v-model="editForm.Name" auto-complete="off" @input='() =>(editForm.Name = editForm.Name.replace(/\s/g,""))'></el-input>
              </el-form-item>

              <el-form-item label="接口地址：">
                  <el-input v-model="editForm.LinkUrl" auto-complete="off"></el-input>
              </el-form-item>
              <el-form-item prop="IsApi" label="是否API：" width sortable>
                  <el-switch v-model="editForm.IsApi"></el-switch>
              </el-form-item>
              <el-form-item prop="ParentId" label="父级目录：" width sortable>
                  <el-cascader
                      v-model="editForm.ParentId"
                      :options="options"
                      :props="defaultProps"
                      :key="isResouceShow"
                      filterable
                      change-on-select
                  ></el-cascader>
              </el-form-item>
              <el-form-item label="启用标识：" prop="IsEnable">
              <el-select v-model="editForm.IsEnable" placeholder="请选择状态">
                  <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.Name"
                  :value="item.value"
                  ></el-option>
              </el-select>
              </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click.native="editFormVisible = false">取消</el-button>
              <el-button type="primary" @click.native="editSubmit" :loading="listLoading">提交</el-button>
          </div>
      </el-dialog>
  </div>
</template>

<script>
import Toolbar from "../../components/Toolbar";
import util from "../../../util/date";
import { getButtonList } from "../../promissionRouter";
import { getApiModulesInfoTree, updateApiModulesInfo,addApiModulesInfo,deleteApiModulesInfo,getApiMenuTree} from '../../api/api';
import Qs from 'qs'
export default {
  components: { Toolbar },
  data() {
    return {
      //是否启用状态
      optionsData: [{  
        value: true,
        name: '启用'
      },{
        value: false,
        name: '禁用'
      }],
      buttonList: [],
      treeData: [],   //列表树
      listLoading: false,
      selectVal: '',//列表选中列
      //顶部筛选条件
      filters: { 
          name: '',
          selectc: true
      },
      addFormVisible: false, //新增界面是否显示
      addFormRules: {
          Name: [{ required: true, message: "请输入接口名称", trigger: "blur" }],
          LinkUrl: [{ required: true, message: "请输入接口地址", trigger: "blur" }]
      },
      editFormVisible: false, //新增界面是否显示
      editFormRules: {
          Name: [{ required: true, message: "请输入接口名称", trigger: "blur" }],
          LinkUrl: [{ required: true, message: "请输入接口地址", trigger: "blur" }]
      },
      statusList: [
          { Name: "启用", value: true },
          { Name: "禁用", value: false }
      ],
      options: [],
      defaultProps: { 
          children: "Children",
          label: "Name",
          value:'ID',
      },
      //编辑
      isResouceShow: 1,
      editForm:{
        Name:'',
        LinkUrl:'',
        IsEnable:true,
        IsApi:false,
        Id:'',    
        ParentId: [], //父级菜单      
      },
      //新增
      addForm:{
        Name:'',
        LinkUrl:'',
        IsEnable:true,
        IsApi:false,
        Id:'',    
        ParentId: [], //父级菜单      
      },
      isRouterAlive: true,
    };
  },
  methods: {
    // 筛选框清空，重新获取数据
    clearContent(){
      this.getApiModules()
    },
    //当前行发生变化时
    selectCurrentRow(val) {
        this.selectVal = val;
    },
    //选项发生变化时
    selsChange: function (val) {
        this.selectVal = val;
    },
    //时间格式化
    formatCreateTime: function(row, column) {
        return !row.CreateTime || row.CreateTime == ""
        ? ""
        : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
    },
    //获取接口列表
    getApiModules(){
        this.listLoading = true;
        let params = {
          apiName: this.filters.name,
          isEnable: this.filters.selectc,
        };
        getApiModulesInfoTree(params).then((res) => {
            this.treeData = res.data.Response;
            this.listLoading = false;

            // if(res.data.Response.PageSize > 0){
            //   this.pages.pageSize = res.data.Response.PageSize
            // }
        });
    },
    load(tree, treeNode, resolve) {
        let params = {
            parentId: tree.ID,
            isEnable: tree.IsEnable
        };
        getApiModulesInfoTree(params).then(res => {
            resolve(res.data.Response);
        });
    },
    //显示新增
    handleAdd(){
      this.addForm = {
        Name: '',
        ID:JSON.parse(localStorage.getItem("user")).ID,
        LinkUrl:'',
        IsApi:true,
        IsEnable: true,
        ParentId:''
      };
      this.addFormVisible = true;
      this.getParNavTree();//获取父节点
    },
    //显示编辑
    handleEdit(){
      let row = this.selectVal;
      if (!row) {
          this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error"
          });
          return;
      }
      this.editForm = Object.assign({}, row);
      this.editFormVisible = true;
      this.getParNavTree();//获取父节点
    },
    //新增
    addSubmit: function () {
      this.$refs.addForm.validate((valid) => {
          if (valid) {
              this.$confirm('确认提交吗？', '提示', {}).then(() => {
                  this.listLoading = true;
                  let params = Object.assign({}, this.addForm);
                  if(!!this.addForm.ParentId && this.addForm.ParentId.length > 0){
                    params.ParentId = this.addForm.ParentId.pop()
                  }
                  // 新建菜单
                  // params = {
                  //   CreateTime: "2021-12-26 14:10:31",CreateUserID: 1,HasChildren: true,IsApi: false,IsDeleted: false,IsEnable: true,Remarks: null,SortNo: 0,
                  //   LinkUrl: "",
                  //   Name: "查看发票",
                  //   ParentId: 149,
                  // }
                  // 新建接口
                  // params = {
                  //   CreateTime: "2021-12-26 14:10:31",CreateUserID: 1,HasChildren: false,IsApi: true,IsDeleted: false,IsEnable: true,Remarks: null,SortNo: 0,
                  //   LinkUrl: "/api/LearningSystem/GetStudentInvoiceAllPageList",
                  //   Name: "获取学员发票列表",
                  //   ParentId: 150,
                  // }
                  addApiModulesInfo(params).then((res) => {
                    if (util.isEmt.format(res)) {
                      this.listLoading = false;
                      return;
                    }

                    if (res.data.Success) {
                      this.listLoading = false;
                      this.$message({
                          message: res.data.Message,
                          type: 'success'
                      });
                      this.$refs['addForm'].resetFields();
                      this.addFormVisible = false;
                      this.getApiModules();
                    }
                    else {
                      this.listLoading = false;
                      this.$message({
                          message: res.data.Message,
                          type: 'error'
                      });
                    }
                  });
                  
              });
          }
      });
    },
    //编辑
    editSubmit: function () {
      this.$refs.editForm.validate((valid) => {
          if (valid) {
              this.$confirm('确认提交吗？', '提示', {}).then(() => {
                  this.listLoading = true;
                  let params = Object.assign({}, this.editForm);
                  if(!!this.addForm.ParentId && this.editForm.ParentId.length > 0){
                    params.ParentId = this.editForm.ParentId.pop()
                  }
                  updateApiModulesInfo(params).then((res) => {
                    if (util.isEmt.format(res)) {
                      this.listLoading = false;
                      return;
                    }

                    if (res.data.Success) {
                      this.listLoading = false;
                      this.$message({
                          message: res.data.Message,
                          type: 'success'
                      });
                      this.$refs['editForm'].resetFields();
                      this.editFormVisible = false;
                      this.isRouterAlive = false;            //先关闭，
                      this.$nextTick(function () {
                        this.isRouterAlive = true;         //再打开
                      }) 
                      this.getApiModules();
                      this.selectVal = ''
                    }
                    else {
                      this.listLoading = false;
                      this.$message({
                          message: res.data.Message,
                          type: 'error'
                      });

                    }
                  });
              });
          }
      });
    },
    // 获取父级菜单
    getParNavTree() {
        var params = {
            userID: JSON.parse(localStorage.getItem("user")).ID
        }
        getApiMenuTree(params).then(res => {
            var data = res.data
            if(data.Success){
                var optionArr = []
                optionArr.push(data.Response)
                this.options = optionArr
            }
        })
    },
    //删除
    handleDel(){
      let row = this.selectVal;
      if (!row) {
          this.$message({
          message: "请选择要删除的一行数据！",
          type: "error"
          });

          return;
      }
      this.$confirm('确认删除该接口吗?', '提示', {
          type: 'warning'
      }).then(() => {
          this.listLoading = true;
          let params = {id: row.ID};
          deleteApiModulesInfo(Qs.stringify(params)).then((res) => {
              if (util.isEmt.format(res)) {
                this.listLoading = false;
                return;
              }

              if (res.data.Success) {
                this.listLoading = false;
                this.$message({
                    message: res.data.Message,
                    type: 'success'
                });
                this.getApiModules();
              }
              else {
                this.listLoading = false;
                this.$message({
                    message: res.data.Message,
                    type: 'error'
                });

              }
          });
      }).catch(() => {

      });
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    // 分页
    handleCurrentChange(val) {
      this.pages.pageIndex = val;
      this.getRoles();
    },
    handleSizeChange(val){
      this.pages.pageSize = val
      this.getRoles();
    },
  },
  mounted() {
    this.getApiModules();
    let routers = window.localStorage.router
    ? JSON.parse(window.localStorage.router)
    : [];
    this.buttonList = getButtonList(this.$route.path, routers);
}
};
</script>
<style lang="stylus" scoped>
.rolesTop{
  width 100%;
  display flex;
}
.rolesInp{
  width 300px;
  margin-right 20px;
}
.pageBar{
  margin-top 15px;
}
</style>
